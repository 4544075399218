import {createContext, Dispatch, SetStateAction} from 'react';

import {XAuthenticate} from '../../types/api/XAuthenticate';

export const UserContext = createContext<{
    userState: XAuthenticate,
    setUserState: Dispatch<SetStateAction<XAuthenticate>>,
    unauthorizedState: XAuthenticate,
    setUnauthorizedState: Dispatch<SetStateAction<XAuthenticate>>,
    isAirdropLayout: boolean,
    setIsAirdropLayout: Dispatch<SetStateAction<boolean>>,
}>({
  userState: {
    user: '',
    data: {
      posts: [],
      activityRewards: {},
      specialActivityRewards: [],
      pointsMultiplier: 1,
      totalPointsEarned: 0,
      activePostsEngagement: {},
      activePostsEngagementRewards: {},
      taggingEngagement: {},
      taggingEngagementRewards: {},
      offSeasonPointsEarned: 0,
      currentSeasonPointsEarned: 0,
    },
    season: null,
    seasonEnds: null,
    prizePool: 0,
    nextSeasonStarts: null,
    referralId: null,
    referralLink: null,
    referralCode: null,
    referralName: '',
    referrals: 0,
    nextGiftAt: 0,
    chestRewards: [],
    claimedRewards: [],
    offSeason: false,
    openlootBound: false,
    bfBound: false,
    paused: false,
  },
  setUserState: () => {
  },
  unauthorizedState: {
    data: {
      posts: [],
      activityRewards: {},
      specialActivityRewards: [],
      pointsMultiplier: 1,
      totalPointsEarned: 0,
      activePostsEngagement: {},
      activePostsEngagementRewards: {},
      taggingEngagement: {},
      taggingEngagementRewards: {},
      offSeasonPointsEarned: 0,
      currentSeasonPointsEarned: 0,
    },
    season: null,
    seasonEnds: null,
    referralId: null,
    referralLink: null,
    referralCode: null,
    referralName: '',
    referrals: 0,
    nextGiftAt: 0,
    chestRewards: [],
    claimedRewards: [],
    offSeason: false,
    openlootBound: false,
    bfBound: false,
    paused: false,
    loaded: false,
  },
  setUnauthorizedState: () => {
  },
  isAirdropLayout: true,
  setIsAirdropLayout: () => {
  },
});