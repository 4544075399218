import styled, {css} from 'styled-components';

import {ILoader} from './Loader';

export const LoaderContainer = styled.div<ILoader>`
    display: inline-block;

    ${({size, borderWidth, color}) => css`
        ${size
    ? css`
                    width: ${size}vw;
                    height: ${size}vw;
                `
    : css`
                    width: 48px;
                    height: 48px;
                `}
        ${borderWidth || color
    ? css`
                    border: ${borderWidth || 5}px solid ${color || '#00EFA0'};
                `
    : css`
                    border: 5px solid #00EFA0;
                `}
    `};

    border-bottom-color: transparent;
    border-radius: 50%;

    box-sizing: border-box;

    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`