import React, {Suspense, Fragment, useEffect, useContext} from 'react';
import {Route, Routes} from 'react-router-dom';

import {IRoute, publicRoutes} from '../routes/routes';

import {UserContext} from '../context/userContext/userContext';
import useScreenSizeHook, {SCREEN_TYPES} from '../hooks/useScreenSizeHook/useScreenSizeHook';

import Loader from './Loader/Loader';

const AppContent = () => {
  const {screenType} = useScreenSizeHook();

  const {setIsAirdropLayout} = useContext(UserContext);

  const loader = (
    <Fragment>
      <div className="loader-container">
        <Loader size={screenType === SCREEN_TYPES.DESKTOP ? 2 : 6}
          borderWidth={screenType === SCREEN_TYPES.DESKTOP ? 5 : 2}/>
      </div>
    </Fragment>
  );

  useEffect(() => {
    if (window.location.href.includes('airdrop')) {
      setIsAirdropLayout(true)
    } else {
      setIsAirdropLayout(false)
    }

    if (window.location.href.includes('.game.')) {
      const modifiedUrl = window.location.href.replace('.game.', '.game');
      window.location.replace(modifiedUrl);
    }
  }, []);

  return (
    <Suspense fallback={loader}>
      <Routes>
        {publicRoutes.map((route: IRoute, idx) => route.element &&
                    <Route key={idx} path={route.path} element={<route.element/>}/>)}
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppContent);
