import {getAssetsPrefix} from '../../utils/getAssetsPrefix';

const ASSETS = {
  components: {
    header: {
      desktop: {
        Discord: getAssetsPrefix('header/Discord.webp'),
        HeaderBackground: getAssetsPrefix('header/HeaderBackground.webp'),
      },
      mobile: {
        HeaderBackground: getAssetsPrefix('header/HeaderBackgroundMobile.webp'),
      },
    },
    footer: {
      desktop: {
        FooterBackground: getAssetsPrefix('footer/FooterBackground.webp'),
      },
      mobile: {
        FooterBackground: getAssetsPrefix('footer/FooterBackgroundMobile.webp'),
      },
    },
  },
  page: {
    desktop: {
      MainBackground: getAssetsPrefix('page/MainBackground.webp'),
      TweetsBackground: getAssetsPrefix('page/TweetsBackground.webp'),
      BFLogo: getAssetsPrefix('page/BFLogo.webp'),
      BFText: getAssetsPrefix('page/BFText.webp'),
      Like: getAssetsPrefix('page/Like.webp'),
      LikeCol: getAssetsPrefix('page/LikeCol.webp'),
      Quote: getAssetsPrefix('page/Quote.webp'),
      QuoteCol: getAssetsPrefix('page/QuoteCol.webp'),
      Reply: getAssetsPrefix('page/Reply.webp'),
      ReplyCol: getAssetsPrefix('page/ReplyCol.webp'),
      Repost: getAssetsPrefix('page/Repost.webp'),
      RepostCol: getAssetsPrefix('page/RepostCol.webp'),
      ViewCol: getAssetsPrefix('page/ViewCol.webp'),
      XLogo: getAssetsPrefix('page/XLogo.webp'),
      CloseButton: getAssetsPrefix('page/CloseButton.svg'),
    },
    mobile: {
      MainBackground1: getAssetsPrefix('page/MainBackgroundMobile1.webp'),
      MainBackground2: getAssetsPrefix('page/MainBackgroundMobile2.webp'),
      TweetsBackground: getAssetsPrefix('page/TweetsBackgroundMobile.webp'),
    },
    ChargingDailyBoxWEBM: getAssetsPrefix('page/ChargingDailyBox.webm'),
    ReadyDailyBoxWEBM: getAssetsPrefix('page/ReadyDailyBox.webm'),
    ChargingDailyBoxMP4: getAssetsPrefix('page/ChargingDailyBox.mp4'),
    ReadyDailyBoxMP4: getAssetsPrefix('page/ReadyDailyBox.mp4'),
    ChargingDailyBoxWEBP: getAssetsPrefix('page/ChargingDailyBox.webp'),
    ReadyDailyBoxWEBP: getAssetsPrefix('page/ReadyDailyBox.webp'),
    BackgroundPoints: getAssetsPrefix('page/BackgroundPoints.webp'),
    CheckMark: getAssetsPrefix('page/CheckMark.webp'),
    Completed: getAssetsPrefix('page/Completed.webp'),
    DefaultTweet: getAssetsPrefix('page/DefaultTweet.webp'),
    GiftBox: getAssetsPrefix('page/GiftBox.webp'),
    PostIconBg: getAssetsPrefix('page/PostIconBg.webp'),
    Tooltip: getAssetsPrefix('page/Tooltip.webp'),
    Uncompleted: getAssetsPrefix('page/Uncompleted.webp'),
    GrayLike: getAssetsPrefix('page/GrayLike.svg'),
    GrayQuote: getAssetsPrefix('page/GrayQuote.svg'),
    GrayRepost: getAssetsPrefix('page/GrayRepost.svg'),
    WhiteLike: getAssetsPrefix('page/WhiteLike.svg'),
    WhiteQuote: getAssetsPrefix('page/WhiteQuote.svg'),
    WhiteReply: getAssetsPrefix('page/WhiteReply.svg'),
    WhiteRepost: getAssetsPrefix('page/WhiteRepost.svg'),
    WhiteView: getAssetsPrefix('page/WhiteView.svg'),
  },
};

export default ASSETS