import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

export const Wrapper = styled.header<{ url: string, screenType: SCREEN_TYPES }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 5.2 : 11.8}vw;

    background-image: url(${({url}) => url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    pointer-events: none;

    box-sizing: border-box;
`

export const WrapperNav = styled.nav<{ screenType: SCREEN_TYPES }>`
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
`

export const WrapperUl = styled.ul<{ screenType: SCREEN_TYPES }>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 0;
    padding: 0;

    list-style-type: none;
`

export const WrapperLi = styled.li`
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    pointer-events: all;
`