import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import DefaultLayout from './components/layout/DefaultLayout';
import {UserProvider} from './context/userContext/userProvider';

import useScreenSizeHook, {SCREEN_TYPES} from './hooks/useScreenSizeHook/useScreenSizeHook';

import Loader from './components/Loader/Loader';

import './scss/style.scss';
import './index.css';

export default function App() {
  const {screenType} = useScreenSizeHook();

  const loading = (
    <div className="lazy-loader-container">
      <Loader size={screenType === SCREEN_TYPES.DESKTOP ? 2 : 6}
        borderWidth={screenType === SCREEN_TYPES.DESKTOP ? 5 : 2}/>
    </div>
  );

  return (
    <UserProvider>
      <Router>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" element={<DefaultLayout/>}/>
          </Routes>
        </Suspense>
      </Router>
    </UserProvider>
  );
}
