import React, {useContext} from 'react';

import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';
import {UserContext} from '../../context/userContext/userContext';

import Timer from '../Timer/Timer';

import ASSETS from '../../assets/images/constants';

import './index.css';
import {Wrapper, WrapperLi, WrapperNav, WrapperUl} from './styles';

const Header = () => {
  const {screenType} = useScreenSizeHook();
  const {userState, unauthorizedState} = useContext(UserContext);

  return (
    <Wrapper screenType={screenType} className='header-visible'
      url={screenType === SCREEN_TYPES.DESKTOP ?
        ASSETS.components.header.desktop.HeaderBackground : ASSETS.components.header.mobile.HeaderBackground}>
      <WrapperNav screenType={screenType}>
        <WrapperUl screenType={screenType}>
          <span
            className={`font-family-Montserrat font-w-400 pulsate-text ${screenType === SCREEN_TYPES.DESKTOP ? 'text-m' : 'text-l-mob'}`}>
              Join New Season!
          </span>
          {/*    {unauthorizedState.loaded && (*/}
          {/*      <WrapperLi className={`font-family-Montserrat font-w-900 text-uppercase orange-color */}
          {/*        ${screenType === SCREEN_TYPES.DESKTOP ? 'text-m' : 'text-m-mob'}`}>*/}
          {/*        {(userState.offSeason || unauthorizedState.offSeason) ? (*/}
          {/*          <>*/}
          {/*            <span className={`${screenType === SCREEN_TYPES.DESKTOP ? 'text-xl' : 'text-xl-mob'}`}>*/}
          {/*              {userState.season || unauthorizedState.season}*/}
          {/*            </span>*/}
          {/*            <span className='margin-top-minus-s pulsate-text'>is live</span>*/}
          {/*          </>*/}
          {/*        ) : (*/}
          {/*          userState.season !== null || unauthorizedState.season !== null) ? (*/}
          {/*            <span*/}
          {/*              className={`${screenType === SCREEN_TYPES.DESKTOP ? 'text-xl' : 'text-xl-mob'}`}>*/}
          {/*              {userState.season || unauthorizedState.season}*/}
          {/*            </span>*/}
          {/*          ) : (*/}
          {/*            (userState.nextSeasonStarts || unauthorizedState.nextSeasonStarts) ? (*/}
          {/*              <span*/}
          {/*                className={`${screenType === SCREEN_TYPES.DESKTOP ? 'text-next-season' : 'text-l-mob'}`}>*/}
          {/*                Season starts in*/}
          {/*              </span>*/}
          {/*            ) : (*/}
          {/*              <>*/}
          {/*                <span*/}
          {/*                  className={`${screenType === SCREEN_TYPES.DESKTOP ? 'text-next-season' : 'text-l-mob'}`}>*/}
          {/*                Final season*/}
          {/*                </span>*/}
          {/*                <span*/}
          {/*                  className={`margin-top-minus-s ${screenType === SCREEN_TYPES.DESKTOP ? 'text-next-season' : 'text-l-mob'}`}>*/}
          {/*                finished*/}
          {/*                </span>*/}
          {/*              </>*/}
          {/*            )*/}
          {/*          )}*/}

          {/*        {(!userState.offSeason || !unauthorizedState.offSeason) && (*/}
          {/*          <span className={`font-family-Montserrat font-w-400 white-color text-lowercase margin-top-minus-s*/}
          {/*        ${screenType === SCREEN_TYPES.DESKTOP ? 'text-s' : 'text-s-mob'}`}*/}
          {/*          style={{opacity: `${(userState.season === null && unauthorizedState.season === null) && (!userState.nextSeasonStarts && !unauthorizedState.nextSeasonStarts) ? 0 : 1}`}}>*/}
          {/*            {((userState.season !== null || unauthorizedState.season !== null) && (Number(userState.seasonEnds) > 0 || Number(unauthorizedState.seasonEnds) > 0)) ? (*/}
          {/*              <span className='pulsate-text'>is live</span>*/}
          {/*            ) : ((Number(userState.nextSeasonStarts) > 0 || Number(unauthorizedState.nextSeasonStarts) > 0) ?*/}
          {/*              <Timer time={Number(userState.nextSeasonStarts || unauthorizedState.nextSeasonStarts)}/> : '')}*/}
          {/*          </span>*/}
          {/*        )}*/}
          {/*      </WrapperLi>*/}
          {/*    )}*/}
        </WrapperUl>
      </WrapperNav>
    </Wrapper>
  );
};

export default Header;