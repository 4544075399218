import {ReactNode, useState} from 'react';

import {UserContext} from './userContext';
import {XAuthenticate} from '../../types/api/XAuthenticate';

type ProfileAvatarProviderProps = {
    children: ReactNode
}

export const UserProvider = ({children}: ProfileAvatarProviderProps) => {
  const [userState, setUserState] = useState<XAuthenticate>({
    user: '',
    data: {
      posts: [],
      activityRewards: {},
      specialActivityRewards: [],
      pointsMultiplier: 1,
      totalPointsEarned: 0,
      activePostsEngagement: {},
      activePostsEngagementRewards: {},
      taggingEngagement: {},
      taggingEngagementRewards: {},
      offSeasonPointsEarned: 0,
      currentSeasonPointsEarned: 0,
    },
    season: null,
    seasonEnds: null,
    prizePool: 0,
    nextSeasonStarts: null,
    referralId: null,
    referralLink: null,
    referralCode: null,
    referralName: '',
    referrals: 0,
    nextGiftAt: 0,
    chestRewards: [],
    claimedRewards: [],
    offSeason: false,
    openlootBound: false,
    bfBound: false,
    paused: false,
  });

  const [unauthorizedState, setUnauthorizedState] = useState<XAuthenticate>({
    data: {
      posts: [],
      activityRewards: {},
      specialActivityRewards: [],
      pointsMultiplier: 1,
      totalPointsEarned: 0,
      activePostsEngagement: {},
      activePostsEngagementRewards: {},
      taggingEngagement: {},
      taggingEngagementRewards: {},
      offSeasonPointsEarned: 0,
      currentSeasonPointsEarned: 0,
    },
    season: null,
    seasonEnds: null,
    referralId: null,
    referralLink: null,
    referralCode: null,
    referralName: '',
    referrals: 0,
    nextGiftAt: 0,
    chestRewards: [],
    claimedRewards: [],
    offSeason: false,
    openlootBound: false,
    bfBound: false,
    paused: false,
    loaded: false,
  });

  const [isAirdropLayout, setIsAirdropLayout] = useState<boolean>(true);

  return (
    <UserContext.Provider
      value={{
        userState,
        setUserState,
        unauthorizedState,
        setUnauthorizedState,
        isAirdropLayout,
        setIsAirdropLayout
      }}>
      {children}
    </UserContext.Provider>
  );
};